import React from 'react'
import CallToAction from '../CallToAction'
import classes from './Header.module.css'
import Logo from '../Logo/Logo'

const Header = () => {
    return (
        <div className={classes.HeaderWrapper}>
            <Logo />
            <div>
                <div className={classes.CallActionHeader} >
                    <div className={classes.CallTitle}>Appelez pour prendre un rendez-vous</div>
                    <span><a href="tel:+41582010611"
                       style={{color: '#ff7f00'}}
                       className={classes.PhoneNumber}


                    >
                        <strong>058 201 06 11</strong>
                    </a></span>
                    <div><strong><em>ou</em></strong></div>
                    <div className={classes.ReserveOnlineMobile}><CallToAction/></div>
                </div>

            </div>

        </div>
    )
}

export default Header
